let globalSearchApiUrl;

const search = {
  timeout: null,
  promise: null,

  getSearchValue(input) {
    let value = jQuery(input).val();
    value = encodeURIComponent(value);
    return value;
  },

  bindActions() {
    const self = this;
    jQuery("#search_input")
      .on("keyup", function () {
        window.clearTimeout(self.timeout);
        self.timeout = window.setTimeout(function () {
          const value = self.getSearchValue("#search_input");
          if (value == "") {
            return false;
          }
          self
            .searchContent(value)
            .done(function (r) {
              jQuery(".results_container_list--empty").hide();
              jQuery("#results-list").empty();
              if (r.data.length > 0) {
                jQuery.each(r.data, function (ind, val) {
                  let title = val.title.replace(
                    new RegExp(decodeURIComponent(value), "gi"),
                    (match) => `<mark>${match}</mark>`
                  );
                  let preMarked = title.split("<mark>")?.[0];
                  let postMarked = title.split("</mark>")?.pop();
                  jQuery("#results-list").append(
                    '<li><a href="' +
                      val.url +
                      '">' +
                      (preMarked
                        ? jQuery("<div>").text(preMarked).html()
                        : "") +
                      (title.match("<mark>(.*)</mark>")?.[0] || "") +
                      (postMarked !== "" && postMarked !== preMarked
                        ? jQuery("<div>").text(postMarked).html()
                        : "") +
                      "</a></li>"
                  );
                  jQuery("#results").show();
                  return ind < 4;
                });
                if (r.data.length > 4) {
                  jQuery("#results-list").append(
                    '<li><a href="/search?q=' +
                      value +
                      '">Show more (' +
                      r.data.length +
                      " results)</a></li>"
                  );
                }
              } else {
                jQuery.each(r.meta.suggestions, function (ind, val) {
                  jQuery("#results-list").append(
                    '<li><a href="/search?q=' +
                      val +
                      '">Did you mean to search "<mark>' +
                      val +
                      '</mark>"?</a></li>'
                  );
                  jQuery("#results").show();
                });
              }
            })
            .error(function (e) {
              // This means we have intentionally aborted the call because the user kept typing
              // So there is no need to show a "no results" or other error message
              if (e.statusText == "abort") {
                return false;
              }
              jQuery("#results-list").empty();
              jQuery("#results-list").append(
                '<li>No results found for "<mark>' +
                  jQuery("<div>")
                    .text(decodeURIComponent(value))
                    .html()
                    .replace(/\s/g, "&nbsp;") +
                  '</mark>"</li>'
              );
              jQuery("#results").show();
            });
        }, 200);
      })
      .on("keypress", function (e) {
        if (e.which == 13) {
          const value = jQuery("#search_input").val();
          window.location.href = "/search?q=" + value;
        }
      });

    jQuery("#search_input--large").on("keyup", function () {
      window.clearTimeout(self.timeout);
      self.timeout = window.setTimeout(function () {
        const value = self.getSearchValue("#search_input--large");
        if (value == "") {
          return false;
        }
        self
          .searchContent(value)
          .done(function (r) {
            jQuery(".results_container_list--empty").hide();
            jQuery(".results_container_list").empty();
            jQuery(".search_header__term").text(
              'Search results for "' + value + '"'
            );
            if (r.data.length > 0) {
              jQuery.each(r.data, function (ind, val) {
                let title = val.title.replace(
                  new RegExp(decodeURIComponent(value), "gi"),
                  (match) => `<mark>${match}</mark>`
                );
                let preMarked = title.split("<mark>")?.[0];
                let postMarked = title.split("</mark>")?.pop();
                jQuery(".results_container_list").append(
                  `<li class="results_container_list__item">
									<div class="row">
										<div class="col-2"><img src="${val.featured_image}" /></div>
										<div class="col-10">
											<div class="results_container_list__item__body">
												<a href="${val.url}" class="results_container_list__item__link">
													<span class="results_container_list__item__tag">${val.friendly_type}</span>
												  <p class="results_container_list__item__title">${
                            (preMarked
                              ? jQuery("<div>").text(preMarked).html()
                              : "") +
                            (title.match("<mark>(.*)</mark>")?.[0] || "") +
                            (postMarked !== "" && postMarked !== preMarked
                              ? jQuery("<div>").text(postMarked).html()
                              : "")
                          }</p >
												</a>										
											</div>
										</div>
									</div>
								</li>`
                );
              });
            } else {
              if (r.meta.suggestions.length > 0) {
                jQuery.each(r.meta.suggestions, function (ind, val) {
                  jQuery(".results_container_list").empty();
                  jQuery(".results_container_list").append(
                    `<li class="results_container_list__item">
										<a href="/search?q=` +
                      val +
                      `" class="results_container_list__item__link">
											<p class="results_container_list__item__title">We could not find anything to match “<mark>${value}</mark>.” Did you mean "<mark>${val}</mark>"</p>
										</a>
									</li>`
                  );
                });
              }
            }
          })
          .error(function (e) {
            jQuery(".results_container_list").empty();
            jQuery(".results_container_list").append(
              `<li class="results_container_list__item">
							<p class="results_container_list__item__title">We could not find anything to match “<mark>${jQuery(
                "<div>"
              )
                .text(decodeURIComponent(value))
                .html()
                .replace(/\s/g, "&nbsp;")}</mark>”</p>
						</li>`
            );
          });
      }, 200);
    });

    jQuery(document).ready(function () {
      let searchParams = new URLSearchParams(window.location.search);
      if (searchParams.has("q") && searchParams.get("q") !== "") {
        let value = searchParams.get("q");
        value = decodeURIComponent(value);
        jQuery("#search_input--large").val(value);
        if (value !== "") {
          jQuery(".search_header__term").text(
            'Search results for "' + value + '"'
          );
        }
        self
          .searchContent(value)
          .done(function (r) {
            jQuery(".results_container_list").empty();
            jQuery(".results_container_list--empty").hide();
            if (r.data.length > 0) {
              jQuery.each(r.data, function (ind, val) {
                let title = val.title.replace(
                  new RegExp(decodeURIComponent(value), "gi"),
                  (match) => `<mark>${match}</mark>`
                );
                let preMarked = title.split("<mark>")?.[0];
                let postMarked = title.split("</mark>")?.pop();
                jQuery(".results_container_list").append(
                  `<li class="results_container_list__item">
									<div class="row">
										<div class="col-2"><img src="${val.featured_image}" /></div>
										<div class="col-10">
											<div class="results_container_list__item__body">
												<a href="${val.url}" class="results_container_list__item__link">
													<span class="results_container_list__item__tag">${val.friendly_type}</span>
													<p class="results_container_list__item__title">${
                            (preMarked
                              ? jQuery("<div>").text(preMarked).html()
                              : "") +
                            (title.match("<mark>(.*)</mark>")?.[0] || "") +
                            (postMarked !== "" && postMarked !== preMarked
                              ? jQuery("<div>").text(postMarked).html()
                              : "")
                          }</p >
												</a>										
											</div>
										</div>
									</div>
								</li>`
                );
              });
            } else {
              if (r.meta.suggestions.length > 0) {
                jQuery.each(r.meta.suggestions, function (ind, val) {
                  jQuery(".results_container_list").empty();
                  jQuery(".results_container_list").append(
                    `<li class="results_container_list__item">
										<a href="/search?q=` +
                      val +
                      `" class="results_container_list__item__link">
											<p class="results_container_list__item__title">We could not find anything to match “<mark>${value}</mark>.” Did you mean "<mark>${val}</mark>"</p>
										</a>
									</li>`
                  );
                });
              }
            }
          })
          .error(function (e) {
            jQuery(".results_container_list").empty();
            jQuery(".results_container_list").append(
              `<li class="results_container_list__item">
							<p class="results_container_list__item__title">We could not find anything to match “<mark>${jQuery(
                "<div>"
              )
                .text(decodeURIComponent(value))
                .html()
                .replace(/\s/g, "&nbsp;")}</mark>”</p>
						</li>`
            );
          });
      } else {
        jQuery(".results_container_list--empty").show();
      }
    });

    jQuery("#search_input").focusin(function () {
      jQuery(".search_input_actions").css("opacity", "1");
    });

    jQuery("#search_input--large").focusin(function () {
      jQuery(".search_input_actions--large").css("opacity", "1");
    });

    jQuery(document).on("click", function (event) {
      if (!jQuery(event.target).closest("#search_input").length) {
        jQuery("#results").hide();
        jQuery(".search_input_actions").css("opacity", "0");
      }
    });

    jQuery(document).on("click", function (event) {
      if (!jQuery(event.target).closest("#search_input--large").length) {
        jQuery(".search_input_actions--large").css("opacity", "0");
      }
    });

    jQuery(".search_input_actions__clear").click(function () {
      jQuery("#results-list").empty();
      jQuery("#search_input").val("");
    });

    jQuery(".search_input_actions--large__clear").click(function () {
      jQuery("#search_input--large").val("");
      jQuery(".results_container_list").empty();
      jQuery(".search_header__term").text("Search");
      jQuery(".results_container_list--empty").show();
    });

    jQuery(".search_input_actions__submit").click(function () {
      const value = jQuery("#search_input").val();
      window.location.href = "/search?q=" + value;
    });
  },

  searchContent(value) {
    const self = this;
    const escape_characters = /[\!"/()\[\]{}^]/gi;

    if (
      !jQuery.isEmptyObject(self.promise) &&
      self.promise.hasOwnProperty("abort")
    ) {
      self.promise.abort();
    }

    self.promise = api.get(
      globalSearchApiUrl.substring(1) +
        "?q[value]=" +
        value.replace(escape_characters, "\\$&") +
        "&override_user_id=" +
        abUser.id
    );

    return self.promise;
  },
};

jQuery(document).ready(function () {
  globalSearchApiUrl = "/search-new";
  search.bindActions();
});
